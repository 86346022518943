<template>
  <div class="wr print-page-component" style="background: #ffffff; height: 100%; float: none !important;">
    <v-toolbar color="grey lighten-4" elevation="1" class="non-printable ttolbar" style="align-items: center">
      <v-btn
          class="grey lighten-3 mr-2"
          depressed
          @click.stop="generate = true"
          :loading="generate"
      >
        Сформувати
      </v-btn>
      <MonthComponent
          v-model="month"
          :depressed="true"
          :class_="'grey lighten-3 mr-3'"
          :label="'Місяць звіту'"
      />
      <v-select :items="documents"
                v-model="document_type"
                class="mr-4"
                label="Документ"
                color="grey darken-1"
                single-line
                hide-details style="max-width: 250px"
      />
      <v-select :items="forms"
                v-model="document_print_form"
                label="Друкована форма"
                color="grey darken-1"
                single-line
                hide-details style="max-width: 240px"
      />
      <v-spacer/>
      <v-btn
          class="grey lighten-3 mr-2"
          icon
          @click.stop="openSettings"
      >
        <v-icon color="teal">mdi-filter-menu-outline</v-icon>
      </v-btn>
      <v-btn
          class="grey lighten-3 mr-2"
          icon
          outlined
          @click.stop="print"
      >
        <v-icon color="primary">mdi-printer</v-icon>
      </v-btn>
      <v-btn
          class="grey lighten-3 mr-2"
          icon
          outlined
      >
        <v-icon color="success">mdi-file-excel-outline</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="column-wrapper" style="display: block; float: none !important;">
      <div class="wrapper-page" style="float: none !important;">
        <component :is="componentCurrent"
                   :print_data="print_data"
                   :organization="organization"
                   :global_settings="settings"
                   :month="month"
                   v-model="generate"
                   v-if="document_type && document_print_form"
                   @closeSettings="closeSettings"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_ORGANIZATION_PRINT_DATA} from "@/store/actions/organization";

export default {
  name: "PrintDocument",
  components: {
    MonthComponent: () => import("@/components/MonthComponent")
  },
  computed: {
    ...mapGetters({
      organization: 'getCurrentOrganization',
      current_month: 'getCurrentMonthLegacy'
    }),
    forms() {
      return this.print_forms[this.document_type]
    },
    componentCurrent() {
      if (!this.document_type && !this.document_print_form) return null
      if (this.document_type === 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME') {
        if (this.document_print_form === 'v1') {
          return () => import("@/components/documentPrintForm/act_work_performed_outcome_all_v1")
        }
      }
      if (this.document_type === 'DOCUMENT_BILL_OUTCOME') {
        if (this.document_print_form === 'v1') {
          return () => import("@/components/documentPrintForm/bill_outcome_all_v1")
        }
      }
      if (this.document_type === 'DOCUMENT_TAX_BILL_OUTCOME') {
        if (this.document_print_form === 'v1') {
          return () => import("@/components/documentPrintForm/tax_bill_outcome_all_v1.vue")
        }
      }
      return null
    }
  },
  data() {
    return {
      settings: false,
      document_type: null,
      document_print_form: null,
      month: null,
      documents: [
        { text: 'Акт виконаних робіт', value: 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME' },
        { text: 'Рахунок за надані послуги', value: 'DOCUMENT_BILL_OUTCOME' },
        { text: 'Податкова накладна (вихідна)', value: 'DOCUMENT_TAX_BILL_OUTCOME' },
      ],
      print_data: {},
      print_forms: {
        'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME': [
          {
            text: 'Типова форма',
            value: 'v1',
            document_type: 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME',
            document_print_form: 'v1',
          }
        ],
        'DOCUMENT_BILL_OUTCOME': [
          {
            text: 'Типова форма',
            value: 'v1',
            document_type: 'DOCUMENT_BILL_OUTCOME',
            document_print_form: 'v1',
          }
        ],
        'DOCUMENT_TAX_BILL_OUTCOME': [
          {
            text: 'Типова форма',
            value: 'v1',
            document_type: 'DOCUMENT_TAX_BILL_OUTCOME',
            document_print_form: 'v1',
          }
        ],
      },
      generate: false
    }
  },
  methods: {
    openSettings() {
      this.settings = !this.settings
    },
    closeSettings() {
      this.settings = false
    },
    print() {
      window.print()
    },
    closeModal() {
      this.$emit('closeModal')
    }
  },
  watch: {
    current_month: {
      immediate: true,
      handler(payload) {
        this.month = payload
      }
    }
  },
  created() {
    this.$store.dispatch(GET_ORGANIZATION_PRINT_DATA)
        .then(
            data => {
              this.print_data = data
            }
        )
  }
}
</script>

<style scoped lang="scss">
  .ttolbar {
    .v-toolbar__content {
      align-items: center;
    }
  }
</style>